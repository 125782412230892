import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useQuery, useLazyQuery } from '@apollo/client';
import { ReactComponent as SearchIcon } from 'assets/icons/icon-search.svg';
import DropdownIcon from 'assets/icons/icon-dropdown-white.svg';
import { dataFromPath } from 'lib/utils';
import { asRem } from 'lib/css';
import { FormItem } from 'core/FormFieldItem';
import { ReactComponent as UpArrow } from 'assets/icons/icon-triangle-up.svg';
import { getAccountKey } from 'core/localStore';
import { StatusError, StatusLoading } from '../Status';

const Required = styled.h6`
  color: var(--color-error);
  font-size: ${asRem(12)};
  margin-top: ${asRem(-20)};
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  background: var(--color-border-2);
  margin-bottom: ${asRem(5)};
  box-sizing: border-box;
  input {
    padding: ${asRem(10)} ${asRem(7)};
    margin: ${asRem(0)};
    width: ${asRem(280)};
    background: var(--color-border-2);
    border: none;
    font-weight: bold;
    font-size: ${asRem(12)};
  }

  .search-icon {
    padding-left: ${asRem(8)};
    height: ${asRem(24)};
    width: ${asRem(24)};
  }
`;

const DropdownMenuTrigger = styled(DropdownMenu.Trigger)`
  padding: 0;
  border: 0;
  user-select: none;
  outline: none;
  width: 100%;
  display: flex;
  align-items: center;

  &:disabled {
    background: none;
    
    .with-disabled {
      opacity: 1;

      input {
        cursor: not-allowed;
      }
    }
  }

  .form-item {
    width: 100%;
    input::-webkit-input-placeholder {
      color: white;
    }
    input[type="text"] {
      background-image: url(${DropdownIcon});
      background-repeat: no-repeat;
      background-position: top ${asRem(20)} right ${asRem(16)};
    }
  }

  .dropdown-icon {
    color: white;
    transform: translateX(${asRem(-20)});
    z-index: 1000;
  }
`;

const DropdownMenuContent = styled(DropdownMenu.Content)`
  border-radius: ${asRem(8)};
  background: var(--color-border-1);
  border: 1px solid var(--color-border-2);
  z-index: 1200;
  box-shadow: 0 ${asRem(8)} ${asRem(8)} rgba(50, 50, 71, 0.08), 0 ${asRem(8)} ${asRem(16)} rgba(50, 50, 71, 0.06);
  max-height: calc(50vh - 80px);
  overflow-y: scroll;
  overflow-style: none;
  scrollbar-width: none; 
  &::-webkit-scrollbar {
    display: none;
  }
  
  .dropdown-item {
    width: 100%;
    padding: ${asRem(10)};
    margin: 0;
    text-align: left;
    color: var(--color-label);
    transition: background 0.3s linear;
    user-select: none;
    outline: none;
    font-weight: bold;
    font-size: ${asRem(12)};
    line-height: ${asRem(20)};

  }

  .dropdown-item[data-highlighted] {
    background: var(--color-border-2);
    cursor: pointer;
  }

  p {
    text-align: center;
    font-size: ${asRem(13)};
    margin: ${asRem(8)};
  }
`;

export function SearchAndSelectDropDown({
  name,
  postVariables,
  setFieldValue,
  nameQuery,
  resourceDef,
  label,
  disabled,
  onSelect = null,
  queryAction = null,
  renderItem = null,
  errorMessage = 'Required',
  useKey = false,
  refresh = false,
  defaultOptions = [],
  skipQuery = false,
  ...formOptions
}) {
  const [focus, setFocus] = useState(false);
  const [selectedValue, setSelectedValue] = useState(formOptions.defaultValue || '');
  const [searchValue, setSearchValue] = useState('');
  const [showError, setShowError] = useState(true);
  const [options, setOptions] = useState(defaultOptions);
  const [keyMap, setKeyMap] = useState({});
  const focusFirstElement = useRef();
  const searchRef = useRef();

  const resolvedQuery = queryAction ?? resourceDef?.queries?.search;
  const resolvedVariables = {
    search: searchValue || '',
  };

  const {
    loading, error, data, refetch,
  } = useQuery(
    resolvedQuery?.query,
    {
      variables: resolvedVariables,
      skip: skipQuery,
    },
  );
  const [getName, {
    loading: nameLoading,
    error: nameError,
    data: names,
  }] = useLazyQuery(nameQuery ? nameQuery.query : resourceDef.queries.name.query);

  const filterOptions = () => {
    const filteredOptions = [];
    const itemsObj = {};
    defaultOptions?.forEach((option) => {
      if (option.name.toString().toLowerCase().includes(searchValue.toLowerCase())) {
        filteredOptions.push(option);
        itemsObj[option.key] = option;
      }
    });
    setKeyMap(itemsObj);
    setOptions(filteredOptions);
  };

  useEffect(() => {
    if (postVariables && searchValue === '' && !skipQuery) {
      if (!useKey) {
        getName({
          variables: {
            resource: {
              _hashkey: postVariables,
            },
          },
        });
      } else {
        getName({
          variables: {
            resource: {
              key: postVariables,
              account: getAccountKey(),
            },
          },
        });
      }
    } else if (!postVariables) {
      setSelectedValue('');
    }
  }, [postVariables, getName, setSearchValue, resourceDef, searchValue]);

  useEffect(() => {
    if (!skipQuery) {
      if (searchValue !== '') {
        refetch();
      }
    } else {
      filterOptions();
    }
  }, [searchValue, refetch]);

  useEffect(() => {
    if (names) {
      const value = dataFromPath(
        names,
        nameQuery ? nameQuery.resourceNamePath : resourceDef.queries.name.resourceNamePath,
      );
      setSearchValue(value);
      setSelectedValue(value);
    }
  }, [setSearchValue, names, resourceDef, nameQuery]);

  useEffect(() => {
    if (focus) {
      if (searchRef.current) {
        searchRef.current.focus();
      }
    }
  }, [focus]);

  useEffect(() => {
    if (refresh) {
      setSelectedValue('');
      setSearchValue('');
    }
  }, [refresh]);

  const changeOptionList = (resp) => {
    const itemsObj = {};
    const optionsList = [];
    dataFromPath(resp, resolvedQuery.itemsPath).forEach((item) => {
      const key = dataFromPath(item, resolvedQuery.resourcePath);
      optionsList.push({
        key,
        name: dataFromPath(item, resolvedQuery.resourceNamePath),
        item,
      });
      itemsObj[key] = item;
    });

    setKeyMap(itemsObj);
    setOptions(optionsList);
  };

  useEffect(() => {
    const resp = dataFromPath(data, resolvedQuery.responsePath);
    if (data && resp) {
      changeOptionList(resp);
    }
  }, [data]);

  useEffect(() => {
    if (defaultOptions.length > 0) {
      setOptions(defaultOptions);
    }
  }, [defaultOptions]);

  const setValue = (option) => {
    if (setFieldValue) {
      setFieldValue(name, option.key);
    }
    setSelectedValue(option.name);
    setSearchValue('');
    if (onSelect) {
      const item = keyMap[option.key] ?? null;
      onSelect(item);
    }
  };

  useEffect(() => {
    if (formOptions?.defaultValue && skipQuery) {
      setSelectedValue(formOptions.defaultValue);
    }
  }, [formOptions?.defaultValue]);

  if (!names && nameLoading) return (<StatusLoading />);
  if (!names && nameError) return (<StatusError error={nameError} />);

  return (
    <div>
      <DropdownMenu.Root
        onOpenChange={() => {
          setFocus((prev) => !prev);
        }}
      >
        <DropdownMenuTrigger disabled={disabled}>
          <FormItem
            className="form-item"
            type="text"
            value={selectedValue}
            disabled={disabled}
            autocomplete="off"
            name={name}
            label={label || name}
            resourceDef={resourceDef}
            nameQuery={nameQuery}
            {...formOptions}
          />
        </DropdownMenuTrigger>
        {formOptions.fieldError && showError && (
          <Required>
            {' '}
            <UpArrow />
            {errorMessage}
          </Required>
        )}
        <DropdownMenuContent
          align="start"
          loop="true"
          sideOffset={-20}
          collisionPadding={{ top: '10', bottom: '10' }}
        >
          <SearchWrapper>
            <SearchIcon className="search-icon" />
            <input
              ref={searchRef}
              type="text"
              value={searchValue}
              placeholder="Type and Search Here"
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === 'ArrowDown') {
                  focusFirstElement.current.focus();
                } else if (e.key !== 'Escape') {
                  e.stopPropagation();
                }
              }}
            />
          </SearchWrapper>
          {loading && (<StatusLoading message="Hold on..." />)}
          {error && (<StatusError error={error} />)}
          {options && options.map((option, index) => (
            index === 0
              ? (
                <DropdownMenu.Item
                  key={option.key}
                  className="dropdown-item"
                  as="input"
                  ref={focusFirstElement}
                  onSelect={() => {
                    setValue(option);
                    setShowError(false);
                  }}
                  value={option.name}
                >
                  {renderItem ? renderItem({ item: option }) : option.name}
                </DropdownMenu.Item>
              )
              : (
                <DropdownMenu.Item
                  key={option.key}
                  className="dropdown-item"
                  onSelect={() => {
                    setValue(option);
                    setShowError(false);
                  }}
                >
                  {renderItem ? renderItem({ item: option }) : option.name}
                </DropdownMenu.Item>
              )
          ))}

          {!loading && !error && options.length === 0
              && (
              <p>
                {formOptions.emptyMessage || `No ${resourceDef.name} found`}
              </p>
              )}
        </DropdownMenuContent>
      </DropdownMenu.Root>
    </div>
  );
}

SearchAndSelectDropDown.propTypes = {
  name: PropTypes.string,
  onSelect: PropTypes.func,
  setFieldValue: PropTypes.func,
  disabled: PropTypes.bool,
  resourceDef: PropTypes.object,
  lable: PropTypes.string,
  queryAction: PropTypes.object,
  postVariables: PropTypes.string,
  nameQuery: PropTypes.object,
  renderItem: PropTypes.func,
  useKey: PropTypes.bool,
  refresh: PropTypes.bool,
  ...FormItem.propTypes,
  errorMessage: PropTypes.string,
  defaultOptions: PropTypes.array,
  refetchTrue: PropTypes.bool,
};
